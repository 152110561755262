import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewChild,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReactionService, AppStateService, HostStateService, TelemetryService, getStepData, LandingService} from '@yoyo/services';
import { ReactionStepDirective, manualDelay } from '@yoyo/shared';
import { LoggerService } from '@yoyoreact/yoyo-logger';

import {
  primaryLand,                          
  secondaryLand,
  thirdLand,
  base1,
  base2,
  base3,
  base4,
  base5,
  base6,
  base7,
  final,
  _baseComponent,
  DynamicComponent,
} from '@yoyo/pages';

export class StepItem {
  constructor(public component: Type<any>, public name: string) {}
}

@Component({
  selector: 'create-reaction',
  templateUrl: './create.component.html',
})
export class CreateReactionComponent implements OnInit {          
 
  activeReactionStep: number;
  @ViewChild(ReactionStepDirective, { static: true })
  reaction_step: ReactionStepDirective;
  reaction_steps = [
    new StepItem(primaryLand, 'primaryLand'),
    new StepItem(secondaryLand, 'secondaryLand'),
    new StepItem(thirdLand, 'thirdLand'),
    new StepItem(base1, 'base1'),
    new StepItem(base2, 'base2'),
    new StepItem(base3, 'base3'),
    new StepItem(base4, 'base4'),
    new StepItem(base5, 'base5'),
    new StepItem(base6, 'base6'),
    new StepItem(base7, 'base7'),
    new StepItem(final, 'final'),
    new StepItem(_baseComponent, '_baseComponent'),
  ];

  isResolvingAppState = true;
  has_validation_error = false;
  host_available: boolean = false;

  private ar = inject(ActivatedRoute);
  private appState = inject(AppStateService);
  private rs = inject(ReactionService);
  private host_state = inject(HostStateService);
  private stepDataService = inject(getStepData);
  private landingService = inject(LandingService);
  private logger = inject(LoggerService);
  private telemetry = inject(TelemetryService);

  constructor() {
    this.host_available = this.host_state.host_available;
    const stepNames = this.reaction_steps.map(step => step.name);
    this.logger.debug('Step names initialized: ' + JSON.stringify(stepNames, null, 2));
    this.stepDataService.setStepArray(stepNames);
  }


  async ngOnInit() {
    this.logger.info('CreateReactionComponent initialized');
    this.telemetry.startRecordingUser()
    if (!this.host_available) {
      this.logger.warn('Host not valid in component');
      this.isResolvingAppState = false;
      this.appState.reactionStepValue$.next(15);
      this.setInitialStepIndex();
    } else {
      //await this.validateReactionId();
      this.isResolvingAppState = false;
      
      const landingPage = this.landingService.setAppLanding(this.appState.appScope)
      this.logger.info('Landing on: ' + landingPage);
      const navTo = this.stepDataService.getStepNumber(landingPage);
      this.appState.reactionStepValue$.next(navTo);

      this.setInitialStepIndex()
    }
  }

  private telemetryRecord(next: number) {
   // this.telemetry.stopRecordingLoad();
    //this.telemetry.stopRecording(-1, 'Load', next);
   // console.log('step telemetery is: ' + JSON.stringify(this.telemetry.stepTrackArray, null,2));
  }

  setInitialStepIndex() {
    this.logger.trace('Setting initial step index');
    this.appState.reactionStepValue$.subscribe((stepIndex) => {
      this.logger.debug('Loading step index: ' + stepIndex);
      this.activeReactionStep = stepIndex;
      this.loadStepperComponent(stepIndex);
    });
  }

  loadStepperComponent(stepIndex: number) {
    this.logger.trace('Loading stepper component for index: ' + stepIndex);
    const currentStepItem = this.reaction_steps[stepIndex];
    const viewContainerRef = this.reaction_step.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(currentStepItem.component);
    this.logger.debug('Component loaded: ' + currentStepItem.name);
  }

}
