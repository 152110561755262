import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {environment} from '@yoyo/env';
import {DetectionObject, Reaction} from '@yoyo/types';
import {AppStateService,CloudStorageService, HostStateService, AuthService, LoggingService} from '@yoyo/services';
import {makeId} from '@yoyo/shared';
import {Observable, throwError } from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReactionService {
    private logger = inject(LoggingService);


    reactionValid: boolean = false;
    reaction_recording_blob: Blob;
    reaction_video_id: string;
    reaction: Reaction;
    reaction_detections: DetectionObject[];
    private fileName: string;

    constructor(
        private http: HttpClient,
        private app_state: AppStateService,
        private fs: CloudStorageService,
        private host_state: HostStateService,
        private authService: AuthService
    ) {
        this.fs.initFirebase();
    }

    getReactionData(reactionId: string): Observable<{ reaction: Reaction }> {
        this.logger.info('Attempting to retrieve reaction data for reactionId: ' + reactionId);
    
        try {
            const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
            const host = this.host_state.currentHostName;
    
            return this.http
                .post<any>(
                    environment.api.reaction.get,
                    { 
                        reactionId,
                        'host': host
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: authToken,
                        },
                        responseType: 'json',
                    }
                )
                .pipe(
                    map(response => {
                        this.logger.trace('getReactionData full response: ' + JSON.stringify(response, null, 2));
                        if (response.payload && response.payload.reaction && response.payload.reaction.data) {
                            this.logger.debug('Successfully retrieved reaction data: ' + JSON.stringify(response.payload.reaction.data, null, 2));
                            // Return the data field as the reaction type
                            return { reaction: response.payload.reaction.data as Reaction };
                        } else {
                            this.logger.error('Invalid response structure for reactionId: ' + reactionId);
                            this.logger.debug('Received response: ' + JSON.stringify(response, null, 2));
                            throw new Error('Invalid response structure');
                        }
                    }),
                    catchError(error => {
                        this.logger.warn('Failed to retrieve reaction data for reactionId: ' + reactionId);
                        this.logger.error('Error details: ', error);
                        return throwError(error);
                    })
                );
        } catch (e) {
            this.logger.error('An error occurred while processing reactionId: ' + reactionId, e);
            throw e;
        }
    }
    
    
    async logAccess(reactionId: string, appScope: string) {
        this.logger.info(`Attempting to log access for reactionId: ${reactionId} with appScope: ${appScope}`);
    
        try {
            const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
            const host = this.host_state.currentHostName;
    
            // Log the parameters before making the HTTP call
            this.logger.debug(`Sending request to log access with host: ${host}, reactionId: ${reactionId}, appScope: ${appScope}`);
    
            const response = await this.http
                .post<{ reaction: Reaction }>(
                    environment.api.reaction.logAccess,
                    {
                        reactionId,
                        app: appScope,
                        host: host
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
    
            // Log the successful response
            this.logger.debug(`Successfully logged access for reactionId: ${reactionId}. Response: ${JSON.stringify(response, null, 2)}`);
            return response;
        } catch (e) {
            // Log the error details for better traceability
            this.logger.error(`Failed to log access for reactionId: ${reactionId} with appScope: ${appScope}. Error details: `, e);
            throw e;
        }
    }
    

/*
    async emailCompletedReaction(): Promise<void> {
        try {
            console.log('Sending with HostID', this.host_state.currentHostConfig.id);
            await this.http
                .post<{ data: Reaction }>(
                    environment.api.reaction.send,
                    {
                        reactionId: this.app_state.current_reaction.reaction_output_id,
                        reaction_doc_id: this.app_state.current_reaction.id,
                        senderEmail:
                        this.app_state.current_reaction.sender_details.email_address,
                        receiverEmail:
                        this.app_state.current_reaction.receiver_details.email_address,
                        hostId: this.host_state.currentHostConfig.id,
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
*/

    async saveMessage(message : string): Promise<void> {
  //      console.log('Msg send being attampeted with content: ' + message);
        try {
            const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
            await this.http
                .put<boolean>(
                    environment.api.reaction.update,
                    {
                        reaction_id: this.app_state.current_reaction.id,
                        host: this.host_state.currentHostName,
                        field: 'response',
                        data: {"type":"msg","msg":message,"video":""}
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                            Authorization: authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async uploadWebcamRecording(field: string): Promise<Observable<boolean>> {
     //   console.log('REACTION SERVICE - started with field = ' + field );
        try {
            console.log('Uploading File');
            this.reaction_video_id = this.app_state.current_reaction.id + '_!_'  +  makeId(10);
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDay();
            const hour = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();


            this.fileName = `${this.reaction_video_id}_${year}${month}${day}${hour}${minutes}${seconds}`;
            console.log('File name: ' + this.fileName + ' of size: ' + this.reaction_recording_blob.size);
            this.app_state.videoId =  this.fileName;
            await this.fs.upload_file(
                `reaction_recordings/${this.fileName}.webm`,
                this.reaction_recording_blob
            );
            return this.getReactionData(this.app_state.current_reaction.id).pipe(
                concatMap((res1) => {
                    const completedReactionIds = [];

                    if(res1.reaction?.completed_reaction_video_ids)
                        completedReactionIds.push(...res1.reaction.completed_reaction_video_ids)

                    completedReactionIds.push(this.fileName);
                    const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
                    return this.http
                        .put<boolean>(
                            environment.api.reaction.update,
                            {
                                reaction_id: this.app_state.current_reaction.id,
                                host: this.host_state.currentHostName,
                                field,
                                data: [...completedReactionIds]
                            },
                            {
                                headers: {
                                    ['Content-Type']: 'application/json',
                                    Authorization: authToken,
                                },
                                responseType: 'json',
                            }
                        )
                })
            )
        } catch (e) {
            console.log(e)
            throw e;
        }
    }

    recordFileSent(type: string): Observable<boolean> {
        const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
        return this.http
            .put<boolean>(
                environment.api.reaction.update,
                {
                    reaction_id: this.app_state.current_reaction.id,
                    field: 'response',
                    host: this.host_state.currentHostName,
                    data: {"type":type,"msg":"","video":this.fileName}
                },
                {
                    headers: {
                        ['Content-Type']: 'application/json',
                        Authorization: authToken,
                    },
                    responseType: 'json',
                }
            )
    }


/*
    async retriveWebcamRecording(): Promise<string> {
        try {
            console.log('Retriving File');
            // Retreive the user recording video from cloud storage
            const videoDownloadURL = await this.fs.retrive_file(
                `reaction_recordings/${this.app_state.current_reaction.reaction_output_id}.mp4`
            );
            return videoDownloadURL;
        } catch (e) {
            throw e;
        }
    }

    async retriveCompletedReaction(): Promise<string> {
        try {
            // Retreive the reaction URL from cloud storage.
            const videoDownloadURL = await this.fs.retrive_file(
                `reactions/${this.app_state.current_reaction.reaction_output_id}.mp4`
            );
            return videoDownloadURL;
        } catch (e) {
            throw e;
        }
    }

*/


    /*
    async initReactionCreation(): Promise<void> {
        try {
            const recording_detections =
                !!this.reaction_detections && !!this.reaction_detections.length
                    ? this.reaction_detections
                    : [];
            await this.http
                .post<{ data: Reaction }>(
                    environment.api.reaction.create,
                    {
                        reactionId: this.app_state.current_reaction.reaction_output_id,
                        reactionDocId: this.app_state.current_reaction.id,
                        reactionDetections: recording_detections,
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
        */

    

    async updateLegalAgreement(reaction_id: string): Promise<void> {
        try {
            await this.http
                .post<{}>(
                    environment.api.reaction.agree,
                    {
                        reaction_id,
                        host: this.host_state.currentHostName,
                        date_time: new Date().toLocaleString(),
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            throw e;
        }
    }


    async convertFinalVideo(): Promise<void> {
        if (this.app_state.videoId === null) {
            console.log('Do not have video_id.');
        } else {
            console.log('video convert request being made.');
            const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
            try {
                const reaction_id = this.app_state.current_reaction.id;
                const video_id = this.app_state.videoId;
                await this.http
                    .post<{}>(
                        environment.api.reaction.convert,
                        {
                            reaction_id,
                            video_id,
                            date_time: new Date().toLocaleString(),
                        },
                        {
                            headers: {
                            'Content-Type': 'application/json',
                            'Authorization' : authToken,
                            },
                            responseType: 'json',
                        }
                    )
                    .toPromise();
            } catch (e) {
                throw e;
            }
        }
    }
    

    async upload(type: string) {
        try {
            const uploadedWebcamRecording = await this.uploadWebcamRecording('completed_reaction_video_ids');
            uploadedWebcamRecording.subscribe({
                next: async (response) => {
                    console.log('React Service - upload emitted complete with response: ' + response);
                    await this.recordFileSent(type).toPromise(); // Convert to Promise to await completion
                  //  await this.convertFinalVideo();
                   // this.app_state.reactionStepValue$.next(9);
                    //this.uploading = false;
                },
                error: (err: any) => {
                    console.log('REVIEW - observed error: ' + err);
                    // Handle error if necessary
                },
                complete: () => {
                    console.log('Upload completed');
                    // Handle completion if necessary
                }
            });
        } catch (error) {
            console.error('Error in upload process:', error);
            // Handle the error if needed
        }
    }

    actionTrigger(url: string): Observable<any> {
        this.logger.info(`Action trigger initiated for url: ${url}`);
    
        const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
    
        // Log the request details
        this.logger.debug(`Sending action trigger request for reactionId: ${this.app_state.current_reaction.reactionId}, host: ${this.host_state.currentHostConfig.name}, targetURL: ${url}`);
    
        return this.http.post<any>(
            environment.api.reaction.actionTrigger,
            {
                reactionId: this.app_state.current_reaction.reactionId,
                host: this.host_state.currentHostConfig.name,
                url: url
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken
                },
                responseType: 'json',
            }
        ).pipe(
            catchError((error) => {
                // Log the error details
                this.logger.error('The action did not post correctly for url: ' + url, error);
                return throwError(() => new Error('Action failed with server error'));
            })
        );
    }
    



    initiliseBlankReaction(){
        //Here to support copy over from editor
    }



}
