
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { environment } from '@yoyo/env';
import { AuthService } from '@yoyo/services';
import { StringifyOptions } from 'querystring';

@Injectable({
    providedIn: 'root',
  })

  export class hostCloudManagement {
  
  constructor(private http: HttpClient,
              private authService: AuthService
          ) {}

   
    async retrieveHost(hostName: string, collection: string) {
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        //  console.log('authToken ' + authToken);
        const response = await this.http
            .post<any>(
            environment.api.host.retrieveHost,
            { collection: collection,
                host_name: hostName
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
  //      console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e
      }
    }


  }

